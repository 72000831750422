import axios from "axios";
import Cookies from "universal-cookie";
import { Notyf } from "notyf";
import { RefreshLogin } from "./utils/refreshAccessToken/refreshAccessToken";
import { debounce } from "./utils/useDebounce";

const httpClient = axios.create({
  baseURL: `https://api.groupoctopus.com/api/v1/`,
  // withCredentials: true
});

httpClient.defaults.headers.common["Authorization"] = "AUTH TOKEN";
httpClient.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
httpClient.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE, OPTIONS";
httpClient.defaults.headers.post["Content-Type"] = "application/json";
httpClient.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
httpClient.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
httpClient.defaults.headers.post["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE, OPTIONS";

const cookie = new Cookies();

httpClient.interceptors.request.use(
  (config) => {
    let token = cookie.get("access_token");
    let refreshtoken = cookie.get("refresh_token");

    if (token) {
      config.headers = Object.assign(config.headers, {
        Authorization: "Bearer " + token,
      });
      return config;
    } else if (refreshtoken && config.url.includes("auth/user/refresh")) {
      config.headers = Object.assign(config.headers, {
        Authorization: "Bearer " + refreshtoken,
      });
      return config;
    } else if (config.method !== "get") {
      return config;
    } else {
      checkCookieAndGet().then((res) => {
        if (res) {
          debouncedGetToken();
          return;
        } else {
          return;
        }
      });
    }
  },
  (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
  (response) => {
    // Edit response config
    return response;
  },
  (error) => {
    const notyf = new Notyf();
    // console.log('config', error.config)

    if (!error.response) {
      notyf.error("Internal Server error 500");
    }

    if (parseInt(error.response?.status) === 422) {
      notyf.error(error.response.data.message);
    }

    if (parseInt(error.response?.status) === 401) {
      notyf.error(error.response.data.message);
      debouncedGetToken();
    }

    if (parseInt(error.response?.status) === 403) {
      notyf.error(error.response.data.message);
      cookie.remove("access_token");
      window.location.href = "/login";
    }

    if (parseInt(error.response?.status) === 413) {
      notyf.error(error.response.data.message);
    }
    if (parseInt(error.response?.status) === 500) {
      notyf.error(error.response.data.message);
    }
    return Promise.reject(error);
  }
);

export const httpGet = (params) =>
  httpClient.request({
    method: "get",
    ...params,
  });

export const httpPost = (params) =>
  httpClient.request({
    method: "post",
    ...params,
  });

export const httpDelete = (params) =>
  httpClient({
    method: "delete",
    ...params,
  });

export const httpPut = (params) =>
  httpClient({
    method: "put",
    ...params,
  });

const checkCookieAndGet = async (config) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let token = cookie.get("access_token");
      if (token) {
        // should trigger refreshLogin false
        resolve(false);
      } else {
        // should trigger refreshLogin true
        resolve(true);
      }
    }, 5000);
  });
};

let debouncedGetToken = debounce(
  () =>
    RefreshLogin(
      "Token has expired, connection established, but your last request failed, please try again"
    ),
  1000
);